import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

const SkillStudioOpened = () => {
  const { id } = useParams(); // Retrieve the post ID from the URL
  const navigate = useNavigate(); // useNavigate replaces useHistory
  const [user, setUser] = useState(null); // To store user data
  const [errorMessage, setErrorMessage] = useState(''); // To store error message
  const [post, setPost] = useState(null); // To store the post data
  const [loading, setLoading] = useState(true); // Loading state
  const [isModalOpen, setIsModalOpen] = useState(false);

  Modal.setAppElement('#root'); // Set the app element for accessibility

  // Fetch user data and authentication status
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
        if (response.data.success) {
          setUser(response.data.user); // Store the authenticated user data
        } else {
          setErrorMessage('You are not authenticated. Please log in.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setErrorMessage('An error occurred while checking authentication.');
      }
    };

    fetchUserData();
  }, []);

    // Fetch post data using the post ID
    useEffect(() => {
        if (user) {
        const fetchPostData = async () => {
            try {
            const response = await axios.get(`https://lernacy.co.za/api/skill-studio/${id}`, {
                withCredentials: true, // Include credentials in the request
            });
            if (response.data.success) {
                setPost(response.data.post); // Store the post data
                setLoading(false); // Stop loading once data is fetched
            } else {
                setErrorMessage('Post not found');
            }
            } catch (error) {
            console.error('Error fetching post data:', error);
            setErrorMessage('An error occurred while fetching the post.');
            }
        };
    
        fetchPostData();
        }
    }, [user, id]);

  // Redirect if user is not authenticated
  useEffect(() => {
    if (errorMessage === 'You are not authenticated. Please log in.') {
      navigate('/login'); // Redirect to login page
    }
  }, [errorMessage, navigate]);

   // Open the modal
   const openModal = () => setIsModalOpen(true);

   // Close the modal
   const closeModal = () => setIsModalOpen(false);

  // Handle error if user is not authenticated
  if (!user) {
    return (
      <div>
        <h1>Skill Studio</h1>
        <p>{errorMessage}</p> 
      </div>
    );
  }

  // Display loading state while fetching data
  if (loading) {
    return (
      <div>
        <h1>Skill Studio</h1>
        <p>Loading post...</p>
      </div>
    );
  }

  return (
    <div className="page-colour-manager">
      <h1 className="activity-name-tag-h1">Skill Studio Task</h1>
      {errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        post && (
          <div className='opened-studio-task'>
            <h2>{post.name}</h2>
            <p><strong>Grade:</strong> {post.grade}</p>
            <p><strong>Subject:</strong> {post.subject}</p>
            <p><strong>Theme:</strong> {post.theme}</p>
            <p><strong>Author:</strong> {post.author}</p>
            <hr />
            <p><strong>Instructions:</strong> <br />{post.instructions}</p>
            {post.pdfUrl && (
              <>
                <button onClick={openModal} className='pdf-open-trigger'>Explore More</button>
                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  contentLabel="PDF Viewer"
                  style={{
                    content: {
                      width: '80%',
                      height: '80%',
                      margin: 'auto',
                      padding: '20px',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    },
                  }}
                >
                  <button onClick={closeModal} style={{ marginBottom: '10px' }}>
                    Close
                  </button>
                  <iframe
                    src={post.pdfUrl}
                    title="PDF Viewer"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                  ></iframe>
                </Modal>
              </>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default SkillStudioOpened;

