import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const SkillStudioOpened = () => {
  const { id } = useParams(); // Retrieve the post ID from the URL
  const [user, setUser] = useState(null); // To store user data
  const [errorMessage, setErrorMessage] = useState(''); // To store error message
  const [post, setPost] = useState(null); // To store the post data
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate(); // To handle navigation
  
  // Fetch user data and authentication status
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/study', { withCredentials: true });
        if (response.data.success) {
          setUser(response.data.user); // Store the authenticated user data
        } else {
          setErrorMessage('You are not authenticated. Please log in.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setErrorMessage('An error occurred while checking authentication.');
      }
    };

    fetchUserData();
  }, []);

  // Fetch post data using the post ID
  useEffect(() => {
    if (user) {
      const fetchPostData = async () => {
        try {
          const response = await axios.get(`http://localhost:3000/skill-studio/${id}`);
          if (response.data.success) {
            setPost(response.data.post); // Store the post data
          } else {
            setErrorMessage('Post not found');
          }
        } catch (error) {
          console.error('Error fetching post data:', error);
          setErrorMessage('An error occurred while fetching the post.');
        }
      };

      fetchPostData();
    }
  }, [user, id]);

  // Handle error if user is not authenticated
  if (!user) {
    return (
      <div>
        <h1>Skill Studio</h1>
        <p>{errorMessage}</p>
      </div>
    );
  }

  // Display loading state while fetching data
  if (loading) {
    return (
      <div>
        <h1>Skill Studio</h1>
        <p>Loading post...</p>
      </div>
    );
  }

  return (
    <div>
      <h1>Skill Studio</h1>
      {errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        post && (
          <div>
            <h2>{post.name}</h2>
            <p><strong>Grade:</strong> {post.grade}</p>
            <p><strong>Subject:</strong> {post.subject}</p>
            <p><strong>Theme:</strong> {post.theme}</p>
            <p><strong>Instructions:</strong> {post.instructions}</p>
            <p><strong>Author:</strong> {post.author}</p>
            {post.pdfUrl && (
              <a href={post.pdfUrl} target="_blank" rel="noopener noreferrer">
                Download PDF
              </a>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default SkillStudioOpened;
