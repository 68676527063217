import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './SkillStudio.css';

const SkillStudio = () => {
  const [user, setUser] = useState(null); // To store user data
  const [loading, setLoading] = useState(true); // Loading state
  const [errorMessage, setErrorMessage] = useState(''); // To store error message
  const [submissions, setSubmissions] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // Search query state
  const [formData, setFormData] = useState({
    name: '',
    grade: '', 
    subject: '', 
    theme: '',
    instructions: '', 
    pdf: null,
    author: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, pdf: file }));
  };

  const handleAddBulletPoint = () => {
    setFormData((prev) => ({
      ...prev,
      instructions: `${prev.instructions}\n• `,
    }));
  };

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/get-skill-studio-submissions', {
          withCredentials: true, // Include cookies for authentication
        });

        if (response.data.success) {
          setSubmissions(response.data.data); // Store the retrieved data
        } else {
          setErrorMessage('Failed to fetch submissions');
        }
      } catch (error) {
        console.error('Error fetching submissions:', error);
        setErrorMessage('An error occurred while fetching submissions');
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true to show "Loading..." on the button
    try {
      // Step 1: Fetch CSRF Token
      const csrfResponse = await fetch('https://lernacy.co.za/api/form', {
        method: 'GET',
        credentials: 'include', // Include cookies for CSRF token
      });
      const { csrfToken } = await csrfResponse.json();
      // Step 2: Prepare Form Data
      const formDataObj = new FormData();
      formDataObj.append('name', formData.name);
      formDataObj.append('grade', formData.grade);
      formDataObj.append('subject', formData.subject);
      formDataObj.append('theme', formData.theme);
      formDataObj.append('instructions', formData.instructions);
      formDataObj.append('author', formData.author);

      if (formData.pdf) {
        formDataObj.append('pdf', formData.pdf);
      }

      // Step 3: Submit Form Data with CSRF Token
      const response = await fetch('https://lernacy.co.za/api/log-skill-studio-data', {
        method: 'POST',
        credentials: 'include', // Include cookies
        headers: {
          'CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        body: formDataObj,
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to submit form');
      }

      console.log(responseData.message);
      alert('Form submitted successfully!');
      setFormData({ // Clear the form
        name: '',
        grade: '',
        subject: '',
        theme: '',
        instructions: '',
        pdf: null,
        author: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form: ' + error.message);
    } finally {
      setLoading(false); // Set loading back to false after the request is complete
    }
  };

  useEffect(() => {
    // Fetch user data from the API
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://lernacy.co.za/api/study', {
          withCredentials: true, // Include credentials in the request
        });

        if (response.data.success) {
          setUser(response.data.user);
        } else {
          console.error('Failed to fetch user data');
          setErrorMessage('You are not authenticated. Please log in to access this page.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setErrorMessage('An error occurred while fetching user data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredAndSortedSubmissions = submissions
    .filter((submission) => {
      const fields = [
        submission.name,
        submission.grade,
        submission.subject,
        submission.theme,
        submission.instructions,
        submission.author,
      ];
      return fields.some((field) =>
        field.toLowerCase().includes(searchQuery)
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  if (loading) {
    return (
      <div className="page-colour-manager">
        <h1 className="activity-name-tag-h1">Skill Studio</h1>
        <p>Loading user data...</p>
      </div>
    );
  }

  return (
    <div className="page-colour-manager">
      <h1 className="activity-name-tag-h1">Skill Studio</h1>

      {errorMessage ? (
        <p style={{ color: 'red' }}>{errorMessage}</p>
      ) : (
        user && (
          <>
            {/* Admin Section */}
            {user.status === 'Admin' && (
              <div className='skill-studio-admin-section'>
                <div>
                  <h2>Admin Section</h2>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>
                        Name (Required):
                      </label><br />
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        Grade (Required):
                      </label><br />
                      <input
                        type="text"
                        name="grade"
                        value={formData.grade}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        Subject (Required):
                      </label><br />
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        Theme (Required):
                      </label><br />
                      <input
                        type="text"
                        name="theme"
                        value={formData.theme}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label>
                        PDF Upload:
                      </label><br />
                      <input
                        type="file"
                        name="pdf"
                        accept=".pdf"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div>
                      <label>
                        Instructions:
                      </label><br />
                      <textarea
                        name="instructions"
                        value={formData.instructions}
                        onChange={handleInputChange}
                        placeholder="Add instructions here..."
                      />
                      <button type="button" className='bullet-point-button' onClick={handleAddBulletPoint}>
                        Add Bullet Point
                      </button>
                    </div>
                    <div>
                      <label>
                        Author (Required):
                      </label><br />
                      <input
                        type="text"
                        name="author"
                        value={formData.author}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <button type="submit" disabled={loading} className='admin-submit-skill'>
                      {loading ? 'Loading...' : 'Submit'}
                    </button>
                  </form>
                </div>
              </div>
            )}

            {/* General Section */}
            <div className='general-section-div-box'>
              <h2>Welcome to the Skill Studio!</h2>
              <p>Let's see what you got!</p>

              {/* Search Box */}
              <input
                type="text"
                placeholder="Search tasks..."
                value={searchQuery}
                onChange={handleSearchChange}
                className='skill-studio-search-box'
              />

              <div className='skill-studio-ul-support-div'>
                {filteredAndSortedSubmissions.length > 0 ? (
                  <ul className='skill-studio-ul'>
                    {filteredAndSortedSubmissions.map((submission) => (
                      <li key={submission._id}>
                        <Link to={`/skill-studio/${submission._id}`} className='skill-studio-link'>
                          <div className='skill-studio-div'>
                            <p><strong>Name:</strong><br /> {submission.name}</p>
                            <p><strong>Grade:</strong><br /> {submission.grade}</p>
                            <p><strong>Subject:</strong><br /> {submission.subject}</p>
                            <p><strong>Theme:</strong><br /> {submission.theme}</p> 
                            <p><strong>Author:</strong><br /> {submission.author}</p>          
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No submissions found.</p>
                )}
              </div>

            </div>
          </>
        )
      )}
    </div>
  );
};

export default SkillStudio;

